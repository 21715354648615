import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-footer-sitemap',
  templateUrl: './footer-sitemap.component.html',
  styleUrls: ['./footer-sitemap.component.scss']
})
export class FooterSitemapComponent implements OnInit {

  emailFormCtrl: FormControl;

  constructor(private dialog: MatDialog,private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.emailFormCtrl = new FormControl('', Validators.required);
  }

  subscribe(){
    this.subscriptionService.addSubscription(this.emailFormCtrl.value).then(()=>{
      const msg = "Thanks for subscribing us for latest updates & offers.";
      this.dialog.open(InfoDialogComponent,{ width: '350px', data: { message: msg } });

      this.emailFormCtrl.reset();
    });
  }
}
