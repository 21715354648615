<form method="POST" class="{{this.formElementStyleClass}}" #contactForm="ngForm" [formGroup]="contactFormGroup" (ngSubmit)="save();">
    <div class="{{this.inputElementStyleClass}}">
      <label for="name" class="fw-medium fg-grey">Fullname</label>
      <input type="text" class="form-control" id="name" formControlName="name" required>
    </div>

    <div class="{{this.inputElementStyleClass}}">
      <label for="email" class="fw-medium fg-grey">Email</label>
      <input type="text" class="form-control" id="email" formControlName="email" required>
    </div>

    <div class="{{this.inputElementStyleClass}}">
      <label for="phone" class="fw-medium fg-grey">Phone(optional)</label>
      <input type="number" class="form-control" id="phone" formControlName="phone">
    </div>

    <div class="{{this.inputElementStyleClass}}">
      <label for="message" class="fw-medium fg-grey">Message</label>
      <textarea rows="6" class="form-control" id="message" formControlName="message" required></textarea>
    </div>

    <p>*Your information will never be shared with any third party.</p>

    <div class="{{this.inputElementStyleClass}} mt-4">
      <button type="submit" class="btn btn-primary">Send Message</button>
    </div>
  </form>