<div class="container mb-5">
    <div class="row justify-content-center text-center wow fadeInUp">
      <div class="col-lg-8">
        <div class="text-center mb-3">
          <img src="../assets/favicon-light.png" alt="" height="80">
        </div>
        <h3 class="mb-3"><span class="fg-primary">Mob</span>ster</h3>
        <p class="caption">Lorem ipsum dolor sit amet consectetur adipisicing elit. <br> Expedita voluptates earum minima reiciendis consectetur veniam aut dignissimos</p>

        <ul class="nav justify-content-center py-3">
          <li class="nav-item"><a href="index.html" class="nav-link fg-white px-4">Home</a></li>
          <li class="nav-item"><a href="" class="nav-link fg-white px-4">Key Features</a></li>
          <li class="nav-item"><a href="" class="nav-link fg-white px-4">Pricing</a></li>
          <li class="nav-item"><a href="" class="nav-link fg-white px-4">Testimonials</a></li>
          <li class="nav-item"><a href="" class="nav-link fg-white px-4">FAQ</a></li>
        </ul>
      </div>
    </div>
  </div>