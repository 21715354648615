import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactusService } from '../contactus.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { Contactus } from '../model/contactus.model';

@Component({
  selector: 'app-home1',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.scss']
})
export class Home1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
