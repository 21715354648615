<div class="page-footer">
    <app-footer-sitemap></app-footer-sitemap>
  
    <hr>
  
    <div class="container copyright-section">
      <div class="row">
        <div class="col-12 col-md-7 py-2">
          <img src="../assets/images/logo.png" alt="" width="40">
          <!-- Please don't remove or modify the credits below -->
          <p class="d-inline-block ml-2">Copyright &copy; 2021 <a href="#" class="fw-medium">MindMilieu Private Limited</a>. All rights reserved</p>
        </div>
        <div class="col-12 col-md-5 py-2">
          <div class="row">
            <div class="col-12 col-md-12">
              <ul class="nav justify-content-end">
                <li class="nav-item"><a href="#" class="nav-link">Terms of Use</a></li>
                <li class="nav-item"><a href="#" class="nav-link">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <ul class="nav justify-content-end">
                <li class="nav-item"><a href="#" class="nav-link">Accessibility Policy</a></li>
                <li class="nav-item"><a href="#" class="nav-link">Cookie Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>