import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Contactus } from './model/contactus.model';


@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  readonly path = 'contactus';

  constructor(private afs: AngularFirestore) { }

  public addContactUs(contactus: Contactus){
    contactus.createdAt = new Date();
    const contactusRef = this.afs.collection(this.path);
    return contactusRef.add({ ...contactus }).then((data:DocumentReference<any>) =>{
      return contactusRef.doc(data.id).update({'id':data.id});
    });
  }
}
