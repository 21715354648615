<main>
  <div class="page-hero-section bg-image hero-mini" style="background-image: url(../assets/img/hero_mini.svg);">
    <div class="hero-caption">
      <div class="container fg-white h-100">
        <div class="row justify-content-center align-items-center text-center h-100">
          <div class="col-lg-6">
            <h3 class="mb-4 fw-medium">Blog</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-dark justify-content-center bg-transparent">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Blog</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 py-3">
          <article class="blog-entry">
            <div class="entry-header">
              <div class="post-thumbnail">
                <img src="../assets/img/blogs/blog_4.jpg" alt="">
              </div>
              <div class="post-date">
                <h3>20</h3>
                <span>Feb</span>
              </div>
            </div>
            <div class="post-title"><a href="blog-details.html">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                pretium</a></div>
            <div class="entry-meta mb-2">
              <div class="meta-item entry-author">
                <div class="icon">
                  <span class="mai-person"></span>
                </div>
                by <a href="#">Admin</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-pricetags"></span>
                </div>
                Category:
                <a href="#">Business</a>,
                <a href="#">Finances</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-chatbubble-ellipses"></span>
                </div>
                <a href="#">24 Comments</a>
              </div>
            </div>
            <div class="entry-content">
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima quo officia tenetur hic facere animi
                doloremque adipisci, fugiat ratione corrupti eius amet exercitationem dolorem inventore eum et placeat,
                sunt ducimus.</p>
            </div>
            <a href="#" class="btn btn-primary">Continue Reading</a>
          </article>

          <article class="blog-entry">
            <div class="entry-header">
              <div class="post-thumbnail">
                <img src="../assets/img/blogs/blog_5.jpg" alt="">
              </div>
              <div class="post-date">
                <h3>20</h3>
                <span>Feb</span>
              </div>
            </div>
            <div class="post-title"><a href="blog-details.html">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                pretium</a></div>
            <div class="entry-meta mb-2">
              <div class="meta-item entry-author">
                <div class="icon">
                  <span class="mai-person"></span>
                </div>
                by <a href="#">Admin</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-pricetags"></span>
                </div>
                Category:
                <a href="#">Business</a>,
                <a href="#">Finances</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-chatbubble-ellipses"></span>
                </div>
                <a href="#">24 Comments</a>
              </div>
            </div>
            <div class="entry-content">
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima quo officia tenetur hic facere animi
                doloremque adipisci, fugiat ratione corrupti eius amet exercitationem dolorem inventore eum et placeat,
                sunt ducimus.</p>
            </div>
            <a href="#" class="btn btn-primary">Continue Reading</a>
          </article>

          <article class="blog-entry">
            <div class="entry-header">
              <div class="post-thumbnail">
                <img src="../assets/img/blogs/blog_6.jpg" alt="">
              </div>
              <div class="post-date">
                <h3>06</h3>
                <span>Jan</span>
              </div>
            </div>
            <div class="post-title"><a href="blog-details.html">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                pretium</a></div>
            <div class="entry-meta mb-2">
              <div class="meta-item entry-author">
                <div class="icon">
                  <span class="mai-person"></span>
                </div>
                by <a href="#">Admin</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-pricetags"></span>
                </div>
                Category:
                <a href="#">Business</a>,
                <a href="#">Finances</a>
              </div>
              <div class="meta-item">
                <div class="icon">
                  <span class="mai-chatbubble-ellipses"></span>
                </div>
                <a href="#">0 Comments</a>
              </div>
            </div>
            <div class="entry-content">
              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima quo officia tenetur hic facere animi
                doloremque adipisci, fugiat ratione corrupti eius amet exercitationem dolorem inventore eum et placeat,
                sunt ducimus.</p>
            </div>
            <a href="#" class="btn btn-primary">Continue Reading</a>
          </article>
        </div>
        <!-- Sidebar -->
        <div class="col-lg-4 py-3">
          <div class="widget-wrap">
            <form action="#" class="search-form">
              <h3 class="widget-title">Search</h3>
              <div class="form-group">
                <span class="icon mai-search"></span>
                <input type="text" class="form-control" placeholder="Type a keyword and hit enter">
              </div>
            </form>
          </div>

          <div class="widget-wrap">
            <h3 class="widget-title">Jobs</h3>
            <ul class="categories">
              <li><a href="#">Graphic Designer <span>12</span></a></li>
              <li><a href="#">Visual Assistant <span>22</span></a></li>
              <li><a href="#">Programing <span>37</span></a></li>
              <li><a href="#">Office Admin <span>42</span></a></li>
              <li><a href="#">Web Designer <span>14</span></a></li>
              <li><a href="#">Language <span>140</span></a></li>
            </ul>
          </div>

          <div class="widget-wrap">
            <h3 class="widget-title">Recent Blog</h3>
            <div class="blog-widget">
              <div class="blog-img">
                <img src="../assets/img/blogs/blog_1.jpg" alt="">
              </div>
              <div class="entry-footer">
                <div class="blog-title mb-2"><a href="#">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                    pretium</a></div>
                <div class="meta">
                  <a href="#"><span class="icon-calendar"></span> July 12, 2018</a>
                  <a href="#"><span class="icon-person"></span> Admin</a>
                  <a href="#"><span class="icon-chat"></span> 19</a>
                </div>
              </div>
            </div>
            <div class="blog-widget">
              <div class="blog-img">
                <img src="../assets/img/blogs/blog_2.jpg" alt="">
              </div>
              <div class="entry-footer">
                <div class="blog-title mb-2"><a href="#">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                    pretium</a></div>
                <div class="meta">
                  <a href="#"><span class="icon-calendar"></span> July 12, 2018</a>
                  <a href="#"><span class="icon-person"></span> Admin</a>
                  <a href="#"><span class="icon-chat"></span> 19</a>
                </div>
              </div>
            </div>
            <div class="blog-widget">
              <div class="blog-img">
                <img src="../assets/img/blogs/blog_3.jpg" alt="">
              </div>
              <div class="entry-footer">
                <div class="blog-title mb-2"><a href="#">Duis feugiat neque sed dolor cursus, sed lacinia nisl
                    pretium</a></div>
                <div class="meta">
                  <a href="#"><span class="icon-calendar"></span> July 12, 2018</a>
                  <a href="#"><span class="icon-person"></span> Admin</a>
                  <a href="#"><span class="icon-chat"></span> 19</a>
                </div>
              </div>
            </div>
          </div>

          <div class="widget-wrap">
            <h3 class="widget-title">Tag Cloud</h3>
            <div class="tag-clouds">
              <a href="#" class="tag-cloud-link">dish</a>
              <a href="#" class="tag-cloud-link">menu</a>
              <a href="#" class="tag-cloud-link">food</a>
              <a href="#" class="tag-cloud-link">sweet</a>
              <a href="#" class="tag-cloud-link">tasty</a>
              <a href="#" class="tag-cloud-link">delicious</a>
              <a href="#" class="tag-cloud-link">desserts</a>
              <a href="#" class="tag-cloud-link">drinks</a>
            </div>
          </div>

          <div class="widget-wrap">
            <h3 class="widget-title">Paragraph</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus itaque, autem necessitatibus voluptate
              quod mollitia delectus aut, sunt placeat nam vero culpa sapiente consectetur similique, inventore eos
              fugit cupiditate numquam!</p>
          </div>
        </div> <!-- end sidebar -->
      </div>
    </div>
  </div>

</main>
