import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {APP_BASE_HREF} from '@angular/common';
import { Home1Component } from './home1/home1.component';
import { Home2Component } from './home2/home2.component';
import { AboutComponent } from './about/about.component';
import { UpdatesComponent } from './updates/updates.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { FooterComponent } from './footer/footer.component';
import { FooterSitemapComponent } from './footer-sitemap/footer-sitemap.component';
import { Footer2SitemapComponent } from './footer2-sitemap/footer2-sitemap.component';
import { Footer2Component } from './footer2/footer2.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ContactusComponent } from './contactus/contactus.component';
import { WebapplicationComponent } from './webapplication/webapplication.component';
import { QualityComponent } from './quality/quality.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { SecurityComponent } from './security/security.component';
import { AutomationComponent } from './automation/automation.component';

@NgModule({
  declarations: [
    AppComponent,
    Home1Component,
    Home2Component,
    AboutComponent,
    UpdatesComponent,
    ContactComponent,
    BlogComponent,
    BlogDetailsComponent,
    FooterComponent,
    FooterSitemapComponent,
    Footer2SitemapComponent,
    Footer2Component,
    HeaderComponent,
    InfoDialogComponent,
    ContactusComponent,
    WebapplicationComponent,
    QualityComponent,
    ComplianceComponent,
    SecurityComponent,
    AutomationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  bootstrap: [AppComponent],
  entryComponents:[
    InfoDialogComponent
  ]
})
export class AppModule { }
