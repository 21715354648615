import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer2-sitemap',
  templateUrl: './footer2-sitemap.component.html',
  styleUrls: ['./footer2-sitemap.component.scss']
})
export class Footer2SitemapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
