import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactusService } from '../contactus.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { Contactus } from '../model/contactus.model';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  @Input()
  inputElementStyleClass: string = "form-group";

  @Input()
  formElementStyleClass: string = "mt-3";

  contactFormGroup: FormGroup;

  constructor(private dialog: MatDialog, private fb: FormBuilder, private contactusService: ContactusService) { }

  ngOnInit(): void {
    this.contactFormGroup = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: '',
      message: ['', Validators.required],
    });
  }

  save(){
    let contact =  new Contactus();
    contact = {...contact, ...this.contactFormGroup.value};
    this.contactusService.addContactUs(contact).then(()=>{
      const msg = "Your message has been delivered. We will get back to you as early as possible.";
      this.dialog.open(InfoDialogComponent,{ width: '350px', data: { message: msg } });

      this.resetForm();
    });
  }

  resetForm(){
    this.contactFormGroup.reset();
  }
}
