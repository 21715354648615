import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  readonly path = 'subscriptions';

  constructor(private afs: AngularFirestore) { }

  addSubscription(email:string){
    let data :any = {};
    data.email = email;
    data.createdAt = new Date();
    const subscriptionRef = this.afs.collection(this.path);
    return subscriptionRef.add({ ...data }).then((data:DocumentReference<any>) =>{
      return subscriptionRef.doc(data.id).update({'id':data.id});
    });
  }

  removeSubscription(id:string){
    let data :any = {};
    data.deletedAt = new Date();
    return this.afs.collection(this.path).doc(id).update({...data}).then(()=>{
      return this.afs.collection(this.path).doc(id).delete();
    });
  }
}
