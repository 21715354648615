<main class="bg-light">

  <div class="page-hero-section bg-image hero-mini" style="background-image: url(../assets/img/hero_mini.svg);">
    <div class="hero-caption">
      <div class="container fg-white h-100">
        <div class="row justify-content-center align-items-center text-center h-100">
          <div class="col-lg-6 hide">
            <h3 class="mb-4 fw-medium">About Us</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-dark justify-content-center bg-transparent">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">About</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-section pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card-page">
            <h2 class="fg-primary">A little something about us</h2>
            <hr>
            <p>If you’re looking for a trusted and dedicated partner for a variety of web-related services, you are at
              the perfect place. We are a leading full-stack IT company known for our high-end services at meaningful
              prices. With an extensive list of clients who keep coming back to us for their new projects related to web
              development, application development, and maintenance & support, we have proven that we work to keep your
              needs as our priority.</p>
            <p>We have acquired experience in completing thousands of projects into different independent domains with
              our technical experts over several years and have provided effective solutions that address the specific
              requirements of our clients. We emphasize primarily on catering to the requirements of our existing
              clients to assist them in development and maintain the right set of software solutions.</p>

            <!-- Video -->
            <div class="text-center py-5 hide">
              <embed class="embed-video"
                src="https://www.youtube.com/embed/k1D0_wFlXgo?list=PLl-K7zZEsYLmnJ_FpMOZgyg6XcIGBu2OX">
            </div>
            <p>Mind Milieu has built an extensively big database of knowledge that allows us to offer affordable and
              achievable to our clients across the globe. We are the best software development company in the market and
              we can prove that with our work and our happy clients over the years. Our services keep you at the top in
              this competitive market.</p>
          </div>
          <div class="card-page mt-3">
            <h2 class="fg-primary">What we do</h2>
            <hr>
            <p>We Design, Develop and Maintain</p>
            <p>Founded in the year 2012, Mind Milieu was created with an exclusive aim of tackling challenges in the IT
              space and to offer effective solutions to industrial challenges. Our application development company has
              helped a number of clients in the domain of application and web development & maintenance, internet
              marketing, and software development so far with our impeccable IT outsourcing services.</p>
            <p>We hire offshore developers to meet your high-end product requirements and we make sure that we do it in
              time. We are a leading full-stack IT company with expertise in quality testing and maintenance support.
              Our team delivers customised software solutions as per your business needs following the project
              methodology as per your choice.</p>
          </div>
          <div class="card-page mt-3">
            <h2 class="fg-primary">Our Process</h2>
            <hr>
            <p>Our Modus Operandi</p>
            <p>Mind Milieu is a one-stop solution for all your software development needs. Our client-oriented approach
              provides you a hassle-free experience. We offer bespoke software packages to meet all your needs.</p>
            <p>The process of onboarding at our Mind Milieu is as follows-</p>
            <p>1. Choose a service from a wide range of highly customizable options.</p>
            <p>2. Schedule a meeting with our officials.</p>
            <p>3. Receive custom made plans that best suit all your business needs.</p>
            <p>4. Just sit back and we will deliver the tailored software solution.</p>
          </div>
          <div class="card-page mt-3">
            <h2 class="fg-primary">Partners</h2>
            <hr>

            <div
              class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 justify-content-center align-items-center mt-5">
              <div class="p-3">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/alter_sport.png" alt="">
                </div>
              </div>
              <div class="p-3">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/cleaning_service.png" alt="">
                </div>
              </div>
              <div class="p-3">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/creative_photo.png" alt="">
                </div>
              </div>
              <div class="p-3 hide">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/global_tv.png" alt="">
                </div>
              </div>
              <div class="p-3 hide">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/net_sport_tv.png" alt="">
                </div>
              </div>
              <div class="p-3 hide">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/news_digital_tv.png" alt="">
                </div>
              </div>
              <div class="p-3">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/spa_beauty.png" alt="">
                </div>
              </div>
              <div class="p-3 hide">
                <div class="img-place client-img">
                  <img src="../assets/img/clients/trivier_group.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main> <!-- .bg-light -->
