<div class="page-footer-section bg-dark fg-white">
    <div class="container">
      <div class="row mb-5 py-3">
        <div class="col-sm-6 col-lg-2 py-3">
          <h5 class="mb-3">Pages</h5>
          <ul class="menu-link">
            <li><a href="automation.html" class="">BPMN 2.0</a></li>
            <li><a href="compliance.html" class="">Compliance</a></li>
            <li><a href="security.html" class="">Performance</a></li>
            <li><a href="quality.html" class="">Quality Assurance</a></li>
            <li><a href="security.html" class="">Security</a></li>
          </ul>
        </div>
        <div class="col-sm-6 col-lg-2 py-3">
          <h5 class="mb-3">Company</h5>
          <ul class="menu-link">
            <li><a href="about.html" class="">About</a></li>
            <li><a href="#" class="hide">Team</a></li>
            <li><a href="#" class="hide">Leadership</a></li>
            <li><a href="#" class="">Careers</a></li>
            <li><a href="#" class="">HIRING!</a></li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <h5 class="mb-3">Contact</h5>
          <ul class="menu-link">
            <li><a href="contact.html" class="">Contact Us</a></li>
            <li><a href="contact.html" class="">Office Location</a></li>
            <li><a href="mailto:info@mindmilieu.com" class="">info@mindmilieu.com</a></li>
            <li><a href="mailto:support@mindmilieu.com" class="hide">support@mindmilieu.com</a></li>
            <li class="hide"><a href="#" class="">+91 8447327698 </a></li>
            <li><a href="#" class="">+91 7217480610</a></li>
          </ul>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <h5 class="mb-3">Subscribe</h5>
          <p>Get some offers, news, or upto date sevices</p>
          <form method="POST" #subscriptionForm="ngForm" (ngSubmit)="subscribe();">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Your email.." [formControl]="emailFormCtrl">
              <div class="input-group-append">
                <button type="submit" class="btn btn-primary"><span class="mai-send"></span></button>
              </div>
            </div>
          </form>
  
          <!-- Social Media Button -->
          <div class="mt-4 hide">
            <a href="#" class="btn btn-fab btn-primary fg-white"><span class="mai-logo-facebook"></span></a>
            <a href="#" class="btn btn-fab btn-primary fg-white"><span class="mai-logo-twitter"></span></a>
            <a href="#" class="btn btn-fab btn-primary fg-white"><span class="mai-logo-instagram"></span></a>
            <a href="#" class="btn btn-fab btn-primary fg-white"><span class="mai-logo-google"></span></a>
          </div>
        </div>
      </div>
    </div>