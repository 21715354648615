import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AutomationComponent } from './automation/automation.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogComponent } from './blog/blog.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ContactComponent } from './contact/contact.component';
import { Home1Component } from './home1/home1.component';
import { Home2Component } from './home2/home2.component';
import { QualityComponent } from './quality/quality.component';
import { SecurityComponent } from './security/security.component';
import { UpdatesComponent } from './updates/updates.component';
import { WebapplicationComponent } from './webapplication/webapplication.component';

const routes: Routes = [
  { path: '',   redirectTo: '/index.html', pathMatch: 'full'},
  { path: 'compliance.html',  component: ComplianceComponent },
  { path: 'security.html', component: SecurityComponent },
  { path: 'automation.html',  component: AutomationComponent},
  { path: 'quality.html',   component: QualityComponent },
  { path: 'index.html', component: Home1Component },
  { path: 'index-2.html', component: Home2Component },
  { path: 'about.html', component: AboutComponent },
  { path: 'contact.html', component: ContactComponent },
  { path: 'blog.html', component: BlogComponent },
  { path: 'updates.html', component: UpdatesComponent },
  { path: 'blog-details.html', component: BlogDetailsComponent },
  { path: 'webapplication.html', component: WebapplicationComponent },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
