<nav class="navbar navbar-expand-lg navbar-light navbar-floating">
    <div class="container nav-header">
      <a class="navbar-brand" href="index.html">
        <img src="../assets/images/Mindmilieu2.png" alt="">
      </a>
      <button class="hide navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="" id="navbarToggler">
        <div fxLayout="row" fxLayoutAlign="center center" class="navbar-nav ml-lg-5 mt-3 mt-lg-0">
          <div class="nav-item">
            <a class="nav-link" href="index.html" [ngClass]="{'selected': this.router.url  === '/index.html'}">Home</a>
          </div>
          <div class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="automation.html">Business Process Automation</a>
              <a class="dropdown-item" href="compliance.html">Compliance Services</a>
              <a class="dropdown-item" href="quality.html">Digital Quality Assurance</a>
              <a class="dropdown-item" href="security.html">Security & Performance</a>
            </div>
          </div>
          <div class="nav-item">
            <a class="nav-link" href="about.html" [ngClass]="{'selected': this.router.url  === '/about.html'}">About</a>
          </div>
          <div class="nav-item hide">
            <a class="nav-link" href="updates.html">What's New</a>
          </div>
          <div class="nav-item">
            <a class="nav-link" href="contact.html" [ngClass]="{'selected': this.router.url  === '/contact.html'}">Contact</a>
          </div>
        </div>
        <div class="ml-auto my-2 my-lg-0 hide">
          <button class="btn btn-dark rounded-pill">Download Now</button>
        </div>
      </div>
    </div>
  </nav>