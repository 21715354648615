<div class="page-hero-section bg-image hero-home-1" style="background-image: url(../assets/img/bg_hero_1.svg);">
  <div class="hero-caption pt-5">
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div class="col-lg-6 wow fadeInUp">
          <div class="badge mb-2 hide">
            <span class="icon mr-1"><span class="mai-globe"></span></span> #2 Editor Choice App of
            2020</div>
          <h1 class="mb-4 main-tagline">Optimization drives automation</h1>
          <p class="mb-4">MindMilieu - All round partner for innovation <br>
            business modeling, and process automation.</p>
          <a href="contact.html" class="btn btn-primary rounded-pill">Contact Us</a>
        </div>
        <div class="col-lg-6 d-lg-block wow zoomIn">
          <div class="img-place mobile-preview shadow floating-animate">
            <img src="../assets/img/app_preview_1.png" alt="" class="hide">
            <img src="../assets/img/business_innovation.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Clients -->
<div class="page-section mt-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-4 py-3 wow zoomIn">
        <div class="img-place client-img">
          <img src="../assets/img/clients/alter_sport.png" alt="">
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 py-3 wow zoomIn">
        <div class="img-place client-img">
          <img src="../assets/img/clients/cleaning_service.png" alt="">
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 py-3 wow zoomIn">
        <div class="img-place client-img">
          <img src="../assets/img/clients/creative_photo.png" alt="">
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 py-3 wow zoomIn hide">
        <div class="img-place client-img">
          <img src="../assets/img/clients/global_tv.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div> <!-- End clients -->

<div class="position-realive bg-image" style="background-image: url(../assets/img/pattern_1.svg);">

  <div class="page-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 py-3">
          <div class="img-place mobile-preview shadow wow zoomIn">
            <img src="../assets/images/left-image.png" alt="">
          </div>
        </div>
        <div class="col-lg-6 py-3 mt-lg-5">
          <div class="iconic-list">
            <div class="iconic-item wow fadeInUp">
              <div class="iconic-md iconic-text bg-warning fg-white rounded-circle">
                <span class="mai-cube"></span>
              </div>
              <div class="iconic-content">
                <h5>Communication</h5>
                <p class="fs-small">Client just need to give us a hint and we take care about the rest</p>
              </div>
            </div>
            <div class="iconic-item wow fadeInUp">
              <div class="iconic-md iconic-text bg-info fg-white rounded-circle">
                <span class="mai-shield"></span>
              </div>
              <div class="iconic-content">
                <h5>Automation/Security</h5>
                <p class="fs-small">Automation and Security is at our core of our development / delivery strategies</p>
              </div>
            </div>
            <div class="iconic-item wow fadeInUp">
              <div class="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                <span class="mai-desktop-outline"></span>
              </div>
              <div class="iconic-content">
                <h5>Technology</h5>
                <p class="fs-small">State of the art tools/software/process architecture design & services adapted in accordance with market</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-1 py-3 mt-lg-5 wow fadeInUp">
          <h1 class="mb-4">Grow together</h1>
          <p class="mb-4">We grow only when our clients grow. Clients growth trajectory is proof of our smooth services and optimized delivery processes</p>
          <a href="#" class="btn btn-outline-primary rounded-pill">How it works</a>
        </div>
        <div class="col-lg-5 py-3">
          <div class="img-place mobile-preview shadow wow zoomIn">
            <img src="../assets/images/right-image.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="page-section bg-dark fg-white">
  <div class="container">
    <h1 class="text-center">Why Choose MindMilieu</h1>

    <div class="row justify-content-center mt-5">
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn">
          <div class="mb-3">
            <img src="../assets/img/icons/rocket.svg" alt="">
          </div>
          <p class="fs-large">Rapid deliveries</p>
          <p class="fs-small fg-grey">We take our work seriously and clients more serious than that which is what reflected in our services delivery</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="200ms">
          <div class="mb-3">
            <img src="../assets/img/icons/testimony.svg" alt="">
          </div>
          <p class="fs-large">Happy Client</p>
          <p class="fs-small fg-grey">Client happiness is our only source of motivation</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="400ms">
          <div class="mb-3">
            <img src="../assets/img/icons/promotion.svg" alt="">
          </div>
          <p class="fs-large">Consistent Improvement</p>
          <p class="fs-small fg-grey">Even for the tough requirements, we always strive for perfection and try to make it happen</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="600ms">
          <div class="mb-3">
            <img src="../assets/img/icons/coins.svg" alt="">
          </div>
          <p class="fs-large">Save Money</p>
          <p class="fs-small fg-grey">Optimize cost and effectiveness with our custom tailored solutions</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="800ms">
          <div class="mb-3">
            <img src="../assets/img/icons/support.svg" alt="">
          </div>
          <p class="fs-large">24/7 Support</p>
          <p class="fs-small fg-grey">Dedicated support for our clients. Our proactiveness makes clients trust us for more challenging work</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 py-3">
        <div class="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="1000ms">
          <div class="mb-3">
            <img src="../assets/img/icons/laptop.svg" alt="">
          </div>
          <p class="fs-large">Advanced Services</p>
          <p class="fs-small fg-grey">Full customised solutions as desired whether is is green/brown field</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-section bg-image" style="background-image: url(../assets/img/pattern_2.svg);">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-5 mb-5 mb-lg-0 wow fadeInUp">
        <h1 class="mb-4">Services</h1>
        <p class="mb-5">MindMilieu offers wide range of services  to cater differnt client needs.<br/> 
          Our main goal is to optimize the development adhering compliance and keeping security in focus.
        </p>
        <a href="contact.html" class="btn btn-gradient btn-split-icon rounded-pill">
          <span class="icon mai-call-outline"></span> Contact Us
        </a>
      </div>
      <div class="col-lg-7">
        <div class="pricing-table">
          <div class="pricing-item active wow zoomIn">
            <div class="pricing-header">
              <h5 class="hide">Business Process Modeling</h5>
              <h1 class="fw-normal">Business <br>Process Modeling</h1>
            </div>
            <div class="pricing-body">
              <ul class="theme-list">
                <li class="list-item">Operations/business alignment</li>
                <li class="list-item">Process communication</li>
                <li class="list-item">Realtime Updates</li>
                <li class="list-item">Control/Consistency</li>
                <li class="list-item">Operational Efficiencies</li>
              </ul>
            </div>
            <button class="btn btn-dark hide">Choose Plan</button>
          </div>
          <div class="pricing-item active wow zoomIn" data-wow-delay="200ms">
            <div class="pricing-header">
              <h5 class="hide">Development/Test Automation</h5>
              <h1 class="fw-normal">Development /<br>Test Automation</h1>
            </div>
            <div class="pricing-body">
              <ul class="theme-list">
                <li class="list-item">Irrelevant results cleanup</li>
                <li class="list-item">Optimization Bandwith/Resources</li>
                <li class="list-item">Requirements Coverage</li>
                <li class="list-item">Total Duration reduction</li>
              </ul>
            </div>
            <button class="btn btn-dark hide">Choose Plan</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-section bg-image bg-image-overlay-dark"
  style="background-image: url(../assets/img/bg_testimonials.jpg);">
  <div class="container fg-white">
    <div class="row">
      <div class="col-md-8 col-lg-6 offset-lg-1 wow fadeInUp">
        <h2 class="mb-5 fg-white fw-normal">Customer Stories</h2>
        <p class="fs-large font-italic">If you want to get up and running quickly, without a lot of pain, MindMilieu makes it easy for you to get building, instead of spending time trying to adjust your processes, team and tools.</p>
        <p class="fs-large fg-grey fw-medium mb-5">Deepanshu, Sales Researcher</p>

        <a href="#" class="btn btn-outline-light rounded-pill">Read Stories <span
            class="mai-chevron-forward"></span></a>
      </div>
    </div>
  </div>
</div>

<div class="page-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 py-3 mb-5 mb-lg-0">
        <div class="img-place w-lg-75 wow zoomIn">
          <img src="../assets/img/illustration_contact.svg" alt="">
        </div>
      </div>
      <div class="col-lg-5 py-3">
        <h1 class="wow fadeInUp">Need a help? <br>
          Don't worry just contact us</h1>

        <form method="POST" class="mt-5 hide">
          <div class="form-group wow fadeInUp">
            <label for="name" class="fw-medium fg-grey">Fullname</label>
            <input type="text" class="form-control" id="name">
          </div>

          <div class="form-group wow fadeInUp">
            <label for="email" class="fw-medium fg-grey">Email</label>
            <input type="text" class="form-control" id="email">
          </div>

          <div class="form-group wow fadeInUp">
            <label for="message" class="fw-medium fg-grey">Message</label>
            <textarea rows="6" class="form-control" id="message"></textarea>
          </div>

          <div class="form-group mt-4 wow fadeInUp">
            <button type="submit" class="btn btn-primary">Send Message</button>
          </div>
        </form>
        <app-contactus inputElementStyleClass="form-group wow fadeInUp" formElementStyleClass="mt-5"></app-contactus>
      </div>
    </div>
  </div>
</div>
